@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
  outline: none;
}
body {
  @apply overflow-x-hidden;
}
::selection {
  /* Code for Firefox */
  color: black;
  background: #1da1f2;
}
button,
input[type="submit"] {
  @apply px-10 cursor-pointer scaleX;
}
a {
  @apply scaleX;
}
li {
  @apply px-10;
}

.Public-pg {
  @apply w-full overflow-x-hidden flex flex-col justify-start items-start;
}
/*components css*/
.topCbnh {
  @apply bg-red-600 text-white   absolute top-2 left-1 p-1 text-xs z-[1] left-[50px] shadow-md;
}
.topCbnh::after {
  content: "";
  border-color: rgb(220 38 38/1) transparent transparent transparent;
  right: -25px;
  @apply w-0 h-0 absolute border-[24px] border-solid top-0 -z-[1];
}
.topCbnh::before {
  content: "";
  border-color: transparent transparent rgb(220 38 38/1) transparent;
  left: -25px;
  @apply w-0 h-0 absolute border-[24px] border-solid top-[-24px] -z-[1];
}

.NoteFound {
  @apply h-[300px] w-full flex flex-col justify-center items-center text-[30px] font-semibold;
}

.GrdX {
  width: calc(100% - 0px);
  @apply flex flex-row relative pt-2 mb-10;
}
.colM1 {
  @apply h-full relative ml-[5%] w-[55%] mr-[50px] relative;
}
.colM2 {
  @apply h-full relative w-[30%];
}

/*public page*/
/*navtop*/

.navTop {
  @apply w-full z-[1002] h-[35px] bg-[#27A8E0] flex flex-row relative;
}
.nav-right {
  @apply flex ml-4  items-center  h-full;
}
.nav-right span {
  @apply text-slate-900 pr-4 text-[13px] font-medium;
}
.nav-right span:first-child {
  @apply ml-5;
}
.sxP {
  @apply ml-[2px] text-sm;
}
.navLeft {
  @apply flex flex-row mb-3 justify-end absolute right-0 top-0;
}
.spnS {
  @apply flex justify-center items-center h-[35px]  p-4 font-medium cursor-pointer;
}
.pNotLogedIn {
  @apply flex flex-row mr-3;
}
.Fmenu_ {
  @apply hidden;
}
.spNL {
  @apply bg-blue-700 text-white rounded-tl-lg rounded-bl-lg;
}
.spnNM {
  @apply bg-[#F2EB11] rounded-tr-lg rounded-br-lg;
}
.spnFash {
  @apply bg-white;
}

.subscribedU {
  @apply w-full h-full z-[1000];
}
.AccImages {
  @apply w-[50px] w-full flex justify-end items-center cursor-pointer;
}
._ptrXo {
  @apply w-[35px] h-[35px] rounded-[50%] bg-[#F2EB11] text-center
 flex justify-center items-center mr-3 text-blue-900;
}
.userAccountName {
  @apply text-[#34014E] text-[15px] px-2 font-semibold;
}
.subTYpes {
  @apply hidden  p-2 bg-red-300 rounded-[30%];
}
.subTYpes.red {
  @apply bg-red-700 text-white text-[13px];
}
.subTYpes.yellow {
  @apply bg-yellow-400;
}
.subTYpes.blue {
  @apply bg-blue-600;
}
.subTYpes.green {
  @apply bg-green-600;
}
._ttyrC32 {
  @apply bg-blue-700 mr-2 py-1 mt-2 px-1 ml-2 rounded-[5px] 
  text-white flex justify-center items-center text-[13px] hidden;
}
.ulSubs {
  @apply hidden flex flex-col items-center;
}
.ulSubs li {
  @apply p-[6px] flex justify-center items-center cursor-pointer scaleX;
}
._tiDkTs {
  @apply cursor-default mb-[20px] flex flex-col items-center;
}
._subsActive .subscribedU {
  @apply w-[250px] mr-2 bg-slate-200 
   rounded-bl-xl rounded-br-xl min-h-[400px];
}
.pActT {
  @apply text-[18px] font-semibold;
}
._subsActive .AccImages {
  @apply flex justify-center items-center;
}
._subsActive .AccImages ._ptrXo {
  @apply h-[50px] w-[50px];
}

._subsActive .ulSubs {
  @apply flex flex-col;
}
._subsActive .userAccountName {
  @apply hidden;
}
._subsActive .subTYpes {
  @apply flex justify-center items-center;
}
._subsActive ._ttyrC32 {
  @apply flex;
}

/*logo advertsment*/
.LGadVert {
  @apply flex flex-row justify-center items-center h-24;
}
.KinYlGo {
  @apply flex justify-start items-center w-[40%] h-[90%];
}
.kinyLiMg {
  @apply w-[85%] h-[95%];
}
.AdBjYgX {
  @apply flex justify-center items-center w-[50%] 
   bg-white h-[90%] pt-[5px];
}

/*main navigation css*/
._cliCds:focus {
  transform: scale(1.01);
}
.MinNav {
  @apply w-full h-[40px] bg-[#27A8E0] z-[1001];
}
.NavDIV {
  @apply w-full h-full flex flex-row justify-start items-center;
}
.NavDIV .MainL {
  @apply h-full flex justify-center items-center pr-5 
    pl-10 relative cursor-pointer;
}
/*[hsl(201,98%,52%)]*/
.NavDIV ._Xmnyxd {
  @apply bg-white border-t-0 border-yellow-400;
}
.NavDIV {
}
.MainL a {
  @apply text-slate-900 font-semibold text-[14px]
    flex inline-block h-full w-full justify-center items-center;
}
.NavDIV .MainL .navGUL {
  border-radius: 0px 0px 5px 5px;
  @apply z-[1001] min-w-0 hidden  flex-col  left-0 absolute w-[185px] 
    min-w-min   bg-white top-[0px] mt-[40px] shadow-lg p-3;
}
.NavDIV .MainL:hover .navGUL {
  @apply flex;
}
.navGUL li {
  @apply py-2 cursor-pointer;
}
.navGUL li {
  @apply text-[14px] scaleX;
}
.navGUL li a:focus {
  @apply scale-[1.2];
}
.LInkX {
  @apply flex h-full w-full justify-center items-center;
}
.plyhd {
  @apply text-red-700 hidden;
}

/*audio*/
.AudX {
}
.AUdXio {
  @apply bg-white fixed sm:w-full  lg:w-[70%] h-[80%] z-[10001]
    lg:left-[15%] top-[50px]  rounded-lg p-2;
}
.overlayx {
  @apply fixed left-0 top-0 h-full w-full
     bg-slate-800 opacity-40 z-[1000];
}
.audioH {
  @apply flex flex-col w-full h-full;
}
.AudH {
  @apply flex w-full  flex relative h-[70px];
}
.audioPlayer {
  @apply w-[80%] flex justify-center  h-[50px] mb-2;
}
.audioXc {
  @apply hidden;
}
.xsDsec {
  @apply hidden;
}
.currentAuTi {
  @apply text-[12px] w-full text-center text-blue-800;
}
.controlsWE {
  @apply w-[70%] p-2 h-full flex  bg-blue-200 pl-1 pr-2
   rounded-[20px] items-center justify-center relative;
}
.fdfdfd::after {
  content: "";
  @apply w-full absolute h-full w-full
   bg-slate-100 rounded-[20px] left-0 top-0;
}
.djjdk {
  @apply text-[12px] mr-1 ml-1;
}
.playPause {
  transition: 0.3s all linear;
  @apply cursor-pointer scaleX;
}
.playPause:active {
  @apply bg-blue-500 rounded-full p-1;
}
.dv2x {
  @apply w-full flex flex-col items-start justify-center;
}
.divMic {
  @apply w-[40px] pl-2;
}
.divRange {
  width: calc(100% - 50px);
  @apply rounded-[20px] flex justify-center items-center;
}
.inpyRange {
  @apply w-[60%] mr-2 ml-2 h-[3px];
}
.currentAu {
  @apply font-bold text-[15px];
}
.currentAu .xsDsec {
  @apply block mr-2;
}
.nKJdsf {
  @apply scaleX;
}
.nhDSs {
  @apply px-1 cursor-pointer scaleX;
}
.pTtitle {
  @apply flex flex-row font-semibold ml-3 px-1 py-2 cursor-pointer;
}
.pTtitle p {
  @apply mt-[-4px] ml-1;
}
.SpNaU {
  @apply flex flex-row absolute right-0 justify-center items-center mr-2;
}
.LinXAuDio {
  @apply flex flex-row;
}
.pClcs {
  @apply cursor-pointer h-[35px] w-[35px]  flex justify-center 
      items-center flex-row rounded-[50%];
}
.pClcs:active {
  @apply scale-110 bg-slate-100 shadow-2xl;
}
.SpNaU svg {
  @apply ml-3;
}
.ULaUDIO {
  @apply flex h-10 w-full flex-row justify-start items-center
  shadow-md;
}
.ULaUDIO li {
  @apply h-full flex flex-row justify-center items-center
   py-3 px-6 font-medium cursor-pointer relative text-[13px];
}
.AudConta {
  height: calc(100% - 120px);
  @apply mt-2;
}
.audio-list {
  @apply w-full h-full overflow-x-hidden overflow-y-auto;
}
.audio-list li {
  @apply w-full flex justify-start items-center p-2 
   border-b-[1px] cursor-pointer pl-2;
}

/*Moving Texts*/

.MvgText {
  @apply mt-1 relative max-w-[100%] h-[25px] m-auto 
  flex flex-row bg-yellow-400;
}
.BrNwsx {
  @apply flex justify-center items-center text-[13px] bg-[#e60000]
  text-white  uppercase h-full  p-2 font-semibold relative left-[0px]
  z-[10];
}

.adsTopImg {
}
.BrNwsx::before {
  content: "";
  border-color: #e60000 transparent transparent transparent;
  right: -25px;
  @apply w-0 absolute border-[25px] border-solid top-0 z-[-1];
}

.TxtUpD {
  letter-spacing: 1px;
  overflow: hidden;
  @apply text-[12px] font-medium w-full h-full  bg-slate-50;
}
.TxtUpD div {
  white-space: nowrap;
  transform: translateX(100%);
  width: auto;
  animation: move 30s linear infinite;
}
@keyframes move {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.TxtUpD div span {
  @apply inline-block;
}
/*.TxtUpD{@apply }*/

/*Login-page*/

.loginTopx {
  @apply bg-white h-full w-full fixed left-0 top-0 z-[100000] bg-white
 overflow-x-hidden overflow-auto flex justify-center;
}
.pClose {
  @apply absolute top-3 right-[20%] h-10 w-10 bg-red-50 p-3 flex 
  justify-center items-center cursor-pointer text-2xl;
}
.LgnCont {
  @apply bg-white  min-h-full flex flex-col justify-start sm:py-12
  relative py-3 w-[50%] self-start  sm:rounded-3xl sm:p-10
   shadow-md;
}

.lgnTP {
  @apply flex flex-col justify-center items-center;
}
.divLGIMG {
  @apply w-full h-[60px] w-[80%];
}
.divLGIMG2 {
  @apply w-full h-[60px] w-[80%];
}
.LGlg-image {
  @apply w-full h-full;
}

.lgn-titl {
  @apply text-[1.4em] font-semibold w-full text-center pt-5 pb-5
 flex justify-center items-center leading-10;
}

.FrmLGN {
  @apply py-8 text-base leading-6 space-y-4 text-gray-700 
  flex justify-start items-start flex-col py-12
  sm:text-lg sm:leading-7;
}
.login_er {
  @apply w-full text-[13px] text-red-600 w-full text-center;
}
.FrmLGN {
  height: calc(100vh - 200px);
}
.InCont {
  @apply relative flex flex-col w-full mb-1;
}
.LGN-UserN {
  @apply placeholder-transparent h-[50px] w-full font-medium 
  border-b-2 border-gray-300 bg-white focus:outline-none
   focus:border-rose-600;
}
.lgNuser2 {
  @apply mb-1;
}
.spN1 {
  @apply flex flex-row;
}
.LGN-user {
  @apply absolute right-2 bottom-3;
}
.lgn-user2 {
  @apply bottom-5;
}
.LGN-Pwd {
  @apply placeholder-transparent h-[50px] w-full border-b-2
   border-gray-300 bg-white focus:outline-none
    focus:border-rose-600;
}
.Lbl-UserN {
  @apply absolute left-0 -top-3.5 text-gray-600 text-sm;
}
.Lbl-pwd {
  @apply absolute left-0 -top-3.5 text-gray-600 text-sm;
}
.LGN-SUB {
  @apply bg-blue-500 text-white rounded-md px-3 py-3 mt-3;
}

.clxSII {
  @apply bg-white;
}
.clxSII .LgnCont {
  @apply flex-row w-[90%] shadow-none min-h-screen  p-0 px-0 py-0;
}
.clxSII .pClose {
  @apply right-3;
}
.clxSII .subSCR {
  @apply h-auto;
}
.clxSII .divLGIMG {
  @apply w-auto hidden;
}
.clxSII .SbPlN {
  @apply w-full;
}
.clxSII .sUBcARD {
  @apply w-full;
}
.clxSII .sUBcARD .SubCard {
  @apply w-[400px];
}
.SubPay {
  @apply w-[40%] mr-3;
}
.subPayment {
  @apply w-full;
}
.pyTitl {
  @apply text-[25px] text-blue-900 p-5 font-semibold;
}
.subpyLi {
  @apply w-full;
}
.subpyLi .mainLxp {
  @apply rounded-sm bg-red-100 w-full shadow-lg mt-2 mb-5 p-2;
}
.subpyLi .ulMidle {
  @apply list-inside list-disc  w-[80%];
}
.subpyLi .title_sUBPay {
  @apply font-semibold p-1  mb-3;
}

/*Inkuru------------------*/
.NpT {
  @apply text-[30px] font-semibold text-[#383838];
}
.Newsx {
  @apply w-full flex flex-col;
}
.PWriter {
  @apply pt-5 pb-5 w-full text-left flex;
}
.TitleImgV {
  @apply text-[#383838] pt-1 pb-1;
}
.News-Summary {
  @apply font-semibold pt-1 pb-1 mt-3;
}
.News-body {
  @apply h-auto;
}
.Nparagr {
  @apply pt-1 pb-1 mt-4;
}
.TitleImage {
  @apply w-full h-auto;
}
.ImgXs {
  @apply w-full h-auto;
}

.DvContainer {
  @apply w-full h-auto flex justify-center items-start 
 mb-5 mt-5;
}
.div_bNyx {
  @apply h-auto w-full flex justify-center  items-start;
}
._noContentDV {
  box-shadow: 0px 0px 1px #787878;
  @apply w-[50%] h-[300px] flex justify-center items-center
 text-[25px] font-semibold mt-5 rounded-2xl p-5;
}
/*amatangazo Division*/

/* _archives page */
._archivesss {
  @apply p-0 w-[90%];
}
._archiveL {
  @apply w-[70%]  flex flex-wrap justify-center h-full mr-4;
}
.kinyOld_sLgf {
  @apply w-[250px] h-[300px] mr-10 cursor-pointer flex flex-col mb-8;
}
._archiveR {
  box-shadow: 0px 0px 5px rgb(224, 219, 219);
  @apply w-[30%] flex pb-6 pt-0 justify-center h-full  ml-6 bg-white;
}
.archiveLI {
  @apply w-[70%]  flex flex-col;
}
.archiveLI li {
  @apply w-full p-2 flex flex-col items-center cursor-pointer;
}
.archiveLI ._liXf4 {
  transition: all 0.4s linear cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply border-b-[0px];
}
.xdDsfr {
  @apply bg-blue-50 p-1 mt-2 rounded-sm;
}
._liC34 {
  transition: all 0.4s linear cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply scaleX;
}
.archiveLI ._liXf4 p {
  @apply p-1 flex justify-center items-center h-full w-full border-b-[1px];
}
.archiveLI li ul {
}
.archiveLI .li_Ttl {
  @apply font-bold text-[20px];
}
._dfRTx3 {
  @apply bg-yellow-300 rounded-md mt-1 font-bold text-blue-900;
}

/*Letters*/
._letterX {
  @apply p-0;
}

/*Magazines*/
._magazines {
  @apply p-0 justify-center items-start;
}
._dvXcX {
  @apply w-[90%] flex flex-wrap justify-start;
}

/*------------------------ NEWS ----------------------------- */
.news-dv {
  @apply lg:pl-10 lg:pr-10 lg:pt-2 w-full h-auto;
}
.ArrLeft {
  @apply w-full h-auto flex;
}
.Nleft {
  @apply h-auto w-[70%] pl-0;
}
.newsXl1 {
  @apply w-full h-full flex flex-col items-center;
}
.NxLfrX {
  @apply min-h-[200px] w-full;
}
.sLgf {
  @apply h-auto relative flex flex-col mb-8;
}
.TpNlXpYCu {
  @apply w-[100%] rounded-sm flex flex-row;
}
/*this to remove if possible*/
.NYnhd {
  @apply w-[75%] flex flex-col mr-5 bg-[#F2EB11];
}
.Nbydksksh {
  @apply w-[25%] bg-slate-100;
}

.topTVCF-Slider {
  @apply bg-white lg:h-[370px] 2xl:h-[600px] relative;
}

.NRight {
  @apply min-h-[800px] w-[30%] bg-white pl-[30px] pl-[50px];
}
.kinyNew {
  @apply h-[500px] w-[100%]  bg-slate-200;
}
.kinyOld {
  @apply h-[500px] bg-slate-200;
}
.F-iconsX {
  @apply absolute text-black z-[100] top-[45%] p-1 rounded-[50%]
     bg-slate-300 cursor-pointer scaleX;
}
.fx1 {
  @apply left-0 -ml-[25px];
}
.fx2 {
  @apply right-0 -mr-[25px];
}
.twTerNwe {
  @apply h-[600px] bg-slate-200;
}
.MinutesLeft {
  @apply ml-2;
}
/*rct*/
.RecTnEW {
  @apply h-auto;
}

/*youtube*/
.RecTYOUtbx {
  @apply h-[500px] bg-slate-50;
}
.BnyH_34AS {
  @apply p-[20px] font-bold w-full text-blue-900;
}

/*new-2*/
.Bnbc_Cx {
  @apply relative h-auto w-full mb-10;
}
.SlierNF {
  @apply w-full flex flex-shrink flex-wrap h-full;
}
.Svertical {
  @apply mb-2;
}
.btnCL {
  @apply p-2 px-8 font-semibold mr-5 border-[1px] border-blue-500 rounded-md;
}
.SlierNF .SliderX {
  transition: 0.5s ease;
  box-shadow: 0px 0px 10px #f2f2f2;
  @apply min-w-[140px]   max-w-[170px] min-h-[230px] rounded-sm border-2 border-white
     bg-white cursor-pointer z-[2] ml-0 mr-3 mb-4;
}
.SlierNF .SliderX:first-child {
  @apply ml-[8px];
}
.SlierNF .SliderX:hover {
  transform: scale(1.06);
  z-index: 5;
}

.Svertical:last-child {
}
.ScroolLeft,
.ScroolRight {
  @apply text-gray-900 font-bold h-[100%] w-[45px]
    leading-[250px] text-[30px] text-center bg-slate-50 absolute top-0 z-10
     cursor-pointer scaleX;
}
.ScroolLeft {
  @apply left-[-0px] bg-white;
}
.ScroolRight {
  @apply right-[0px] bg-white;
}
.NewStop,
.imgXXXVV {
  @apply w-full h-[120px];
}
.NewsIMG,
.iMgTop {
  @apply h-full w-full border-none border-0 object-fill
         bg-slate-200;
}

._testx {
  @apply w-[200px];
}
._testx .NewStop {
  @apply w-full bg-[#F7F9F9];
}
._testx .xnhd {
  @apply p-2 min-h-[40px] mb-2 mt-2 rounded-md w-full bg-[#F7F9F9];
}
._testx .HdXV {
  @apply w-full p-5 bg-[#F7F9F9] rounded-md;
}
.dvBY {
  @apply w-full text-[20px] text-center font-medium  text-blue-800 py-3;
}
.NewsIMG:empty,
.iMgTop:empty,
.dgDF,
.VdfrXS,
.imgXXXVV:empty,
.imgXfUl:empty,
.imgXDiv2 {
  width: 100%;
  height: 100%;
  cursor: progress;
  border: 0px solid #dddd;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  animation: loadingNN 1.5s infinite;
}

@keyframes loadingNN {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
.NewsBottom {
  height: calc(100% - 120px);
  @apply w-full;
}
.NewTitle {
  @apply w-full h-full whitespace-pre-line mb-0;
}
.NbytC {
  @apply text-[10px] font-light flex justify-center flex-row p-2;
}
.NewTitle .HdXV {
  @apply ml-2 h-auto;
}

.NewX1 {
  @apply w-[180px] h-[150px];
}
.NewX2 {
  width: calc(100% - 200px);
}

.thumbSection {
  @apply pt-[3%] text-white;
}
.thumbTitle {
  @apply text-[16px] leading-[14px] font-[500];
}

._AdvertSmX1 {
  @apply w-full h-[150px] border-0 mb-10 flex justify-center items-center bg-blue-50;
}
._adsxss {
  @apply w-[90%];
}
._booksDivsio {
  @apply w-full h-auto  p-5 mb-10 overflow-auto;
}
._bNkssd3 {
  @apply font-semibold w-full  text-blue-900 p-1;
}
._AmatangazoDv {
  @apply min-h-[500px] p-2 bg-slate-50;
}
._mnyHNgt {
  @apply font-semibold;
}

/*
.TpNlFt{@apply lg:min-h-[550px] lg:max-h-[auto]  2xl:min-h-[780px]}
.TpNlXpYCu{@apply    w-[55%]  relative
 rounded-sm flex flex-col}
 .topTVCF-Slider{
  @apply lg:h-[370px] 2xl:h-[600px] bg-slate-200  relative overflow-hidden}
*/

.iMgTop {
  @apply absolute block h-full border-0 border-none  m-auto w-full;
}
.btmYVdg-t {
  @apply min-h-[auto] max-h-[250px] relative pt-3 bottom-0
  opacity-100 z-10 bg-[#F2EB11];
}
.spNNTtl {
  @apply font-semibold text-lg w-full p-3 cursor-pointer;
}

.scaleX:active {
  transform: scale(1.03);
}
.divAuxXD {
  @apply relative flex ml-5 pl-5;
}
.AudioButton {
  @apply bg-red-700 absolute z-40 scaleX
  right-0 p-4 pt-2 pl-[12px] pr-2 h-10 w-10  mr-2 text-white
  cursor-pointer rounded-full -top-5;
}
._audioXXX {
  @apply bg-white  scaleX  cursor-pointer text-red-600;
}
.ActinDVX {
  @apply pt-2 pb-2 right-2 bottom-2 flex flex-row justify-end flex-grow-0 flex-shrink-0;
}
.ContaiSpN {
  @apply flex flex-row ml-5 self-center scaleX cursor-pointer;
}
.Nvyx {
  @apply p-1 bg-white text-xs pr-2 pl-2 pt-1 pb-1 flex justify-center;
}
.BVGHx {
  @apply bg-red-600 text-white;
}
.Nvyx p {
  @apply ml-1 mt-[-2px];
}
.NbvyX {
  @apply p-1 bg-slate-300 relative text-xs;
}
.NbvyX::before,
.Nwht::before {
  content: "";
  content: "";
  border-color: transparent rgb(203, 213, 255, 1) transparent transparent;
  left: -10px;
  @apply w-0 absolute border-[5px] border-solid top-[7px];
}
.Nwht::before {
  content: "";
  content: "";
  border-color: transparent white transparent transparent;
  left: -10px;
  @apply w-0 absolute border-[5px] border-solid top-[7px];
}
.Nwht {
  @apply bg-white;
}

.SocXl {
  @apply p-2 pt-2 pl-[9px] pr-2 h-8 w-8  mr-2 text-white
   cursor-pointer rounded-full scaleX cursor-pointer;
}
.scxl {
  @apply ml-7;
}
.fcBk {
  background-color: #4267b2;
}
.tWITTE {
  background-color: #1da1f2;
}
.whatps {
  background: #25d366;
}

.TpNexfd {
  @apply h-[300px];
}

.KinyTop {
  height: calc(100% - 80px);
  @apply flex justify-center items-center font-semibold text-blue-900 w-full border-0 bg-slate-100 relative;
}
.pXX {
  @apply w-full p-2 text-center;
}
.dgDF {
  @apply w-full h-full;
}
.KinyImg {
  @apply h-full w-full bg-[#F1F1FA] block border-0;
}
.KinyBtM {
  @apply cursor-pointer h-[80px] w-full bg-[#27A8E0] text-white text-center
 text-[16px] font-medium p-2;
}

/*book list*/
.bdv_books {
  @apply flex-wrap justify-center w-full flex-col justify-start items-center;
}
.XcnyNs {
  @apply justify-start;
}
.XcnyNs ._dvXcX {
  @apply w-full flex right-0;
}
.bookList {
  box-shadow: 0px 0px 4px #eee;
  @apply w-[45%] flex 
  self-start  rounded-md mt-2 bg-white mr-7 mb-3 h-[290px];
}
.bookLeft {
  @apply w-[40%] h-full bg-slate-100;
}
.bookLeft img {
  border-radius: 5px 0px 0px 5px;
  @apply w-full h-full;
}
.bookRight {
  border-radius: 5px 0px 0px 5px;
  @apply w-[60%] h-full flex flex-col p-4;
}
.bookAuthor {
  @apply w-full font-semibold text-blue-900;
}
._booL {
  @apply w-full p-1;
}
.bookTitle {
  @apply text-[20px] font-semibold;
}
.Price2_1 {
  @apply flex;
}
.Price2_1 p {
  @apply pr-4 text-blue-700;
}
.Price2_1 p:first-child {
  @apply text-red-600 line-through;
}
.XcnyNs .bookList {
  @apply flex-col w-[200px] h-[500px] text-[13px];
}
.CdrTR {
  @apply bg-slate-100 cursor-not-allowed;
}
.XcnyNs .bookLeft {
  @apply h-[200px] w-full;
}
.XcnyNs .bookLeft img {
  @apply rounded-none;
}
.XcnyNs .bookRight {
  @apply w-full bg-white;
}
.XcnyNs .bookTitle {
  @apply text-[14px] w-full;
}
._pagination {
  @apply w-full mt-5 flex justify-center items-center;
}
.liPagination {
  @apply min-w-[20%] flex mt-2 justify-center items-center;
}
.liPagination li {
  @apply bg-blue-700 text-white w-[40px] h-[40px]
 rounded-md justify-center items-center flex cursor-pointer;
}
._pgN {
  @apply bg-blue-900 text-white p-3 rounded-md;
}
._pPrev {
}
._pnext {
}
._clsYt {
  @apply w-full  p-4 mt-2 mr-2 rounded-md bg-[#F7F9F9] flex justify-start items-center;
}
/*--show kinya-facebook--*/
._HiddeNx {
  @apply hidden;
}
.showKinyaPdf {
  @apply fixed h-full w-[100%] bg-white z-[10000] left-0 top-0;
}
._kinyatop {
  @apply w-full h-[50px] bg-blue-100 relative;
}
.fx33 {
  @apply right-1 top-[7px] bg-red-600 text-white;
}
._kinyaButton {
  height: calc(100% - 50px);
  @apply w-full bg-blue-200;
}
._kinyPhotoContainer {
  @apply w-[100%] h-[100%] overflow-x-auto overflow-y-auto;
}
.DcVFR {
  @apply flex justify-center items-center h-auto w-full pt-5 pb-5;
}
.pClodsd {
  @apply w-full text-[20px] font-semibold h-full flex justify-center
 items-center;
}
.DcVFR canvas {
  @apply h-full w-full rounded-lg;
}
canvas.canvasClassName {
  @apply w-full h-full;
}
.PTFdf {
  @apply w-full h-full flex justify-center items-center;
}
.PTFdf .F-iconsX {
  @apply relative mr-4;
}
.convasClassName {
  @apply w-full h-full;
}
.p-title {
  @apply text-[17px] font-bold mx-6;
}
._kinyPrevNext {
}
._kinyPrevNext .F-iconsX {
  @apply bg-blue-700 text-white p-[6px];
}
.fx22 {
  @apply right-[20px];
}
.fx11 {
  @apply left-[20px];
}

/*===============title names===============*/
._amatangazoT {
  @apply p-0;
}
._hjHury34 {
  @apply w-[85%] h-full flex  flex-row justify-start items-start h-full;
}
._titNyt2 {
  @apply w-[70%] flex min-h-[500px] mr-7 rounded-lg bg-slate-100 p-3;
}
.amTdvTirs {
  @apply text-[14px] font-medium w-full py-4;
}
.imgXDiv {
  @apply w-full min-h-[300px];
}
.imgXDiv2 {
  @apply bg-red-50 h-[500px];
}
.imgXfUl {
  @apply w-full min-h-[300px] h-auto rounded-[10px];
}
._hhY323 {
  @apply w-[30%] p-3 flex shadow-sm min-h-[500px] flex  flex-col bg-slate-50 ml-1;
}
.ulAmatangazoX {
  @apply rounded-md w-full h-full overflow-x-hidden overflow-y-auto;
}
.ulAmatangazoX li {
  @apply w-full cursor-pointer scaleX flex flex-row  p-3 bg-white mb-2;
}
.ulAmatangazoX li p {
  @apply self-center;
}
.ulAmatangazoX li p:first-child {
  @apply pr-2;
}
._JKnhft {
  @apply w-full text-center text-blue-900 font-semibold 
   border-b-[2px] mb-3 p-2;
}
.pFeatAma {
  @apply text-blue-900 font-semibold;
}

/* ================= FOOTER ===================== */

.MainFooter {
  @apply bg-gradient-to-tr from-[#1DA1F2] to-[rgb(29,161,242)]
  flex flex-row w-full h-[350px];
}
.Fdiv {
  width: calc(100% / 3);
}
.footerLeft {
  @apply h-full bg-[#076cd0] relative p-10;
}
.leftUl {
  @apply w-full flex flex-row;
}
.fUL {
  @apply w-full  mr-2 self-start;
}
.fUL li {
  @apply text-left border-b-[0px]  cursor-pointer mb-2 ml-1 flex  w-full;
}
.fUL li a {
  @apply block w-full h-full scaleX   text-slate-300;
}
.fUL li a:active {
  font-size: 18px;
}
.BtM {
  @apply w-full p-2 text-center font-medium absolute bottom-10 left-0;
}
.FCnter {
  @apply flex justify-start p-10 items-start flex-col
 bg-[#1472d1];
}
.FCnter p {
  @apply flex flex-row justify-center items-center w-full p-1;
}
.texc {
  @apply text-[17px] text-slate-300;
}
.KinyTxt {
  text-shadow: 2px 0px 2px white;
  color: white;
  @apply text-[35px] w-full text-center p-2;
}
.FcICont {
  @apply flex text-slate-100 flex-row w-full  flex justify-center mt-3;
}
.fcIcX {
  @apply p-[6px] flex justify-center scaleX rounded-md  mt-1 ml-2 cursor-pointer;
}
.fcIcX.fb {
  @apply bg-[#4267B2];
}
.fcIcX.tw {
  @apply bg-[#1DA1F2];
}
.fcIcX.wh {
  @apply bg-[#25D366];
}
.fcIcX.yb {
  @apply bg-[#FF0000];
}

.fcIcX p {
  @apply font-medium -mt-1 text-[16px];
}
.Fright {
  @apply h-full bg-[#076cd0] text-slate-300 flex flex-col justify-start p-10;
}
.frmSubs {
  @apply w-full flex flex-col;
}
.frmSubs label {
  @apply my-2;
}
.frmSubs h1 {
  @apply text-[23px] font-medium;
}
.SubBtn {
  @apply p-2 px-10 my-3 self-start bg-green-500 text-white;
}
.subsCr {
  @apply p-2  border-2 border-white;
}

/*Other -RECENT & OTHER KINYA*/
.NewRCTR {
  @apply h-[600px];
}
.nEWRcX {
  @apply rounded-sm flex flex-col bg-slate-200;
}
.ArcKiNY {
  @apply bg-slate-200;
}

/*News Twitter & youtube*/
.nEwScila {
  @apply h-[500px];
}
.YoutGalery {
  @apply rounded-sm flex flex-col bg-slate-200;
}
.twitterCont {
  @apply bg-slate-200;
}

/*======= Bottom Banner ======================*/

.BottomBanner {
  @apply h-[150px] w-full bg-slate-300 mb-2;
}

/*alert Overlay Subscription*/
.p-adverts {
  @apply w-[80%] text-blue-900 font-medium text-center p-4;
}
.alertSub {
  @apply w-screen  h-screen fixed left-0 top-0 bg-transparent z-[100000]
 flex justify-center items-center;
}
.alertOverlay {
  @apply w-full h-full left-0 top-0
  bg-slate-900 absolute -z-10 opacity-25;
}
.alerSubDiv {
  @apply relative w-[60%] h-[90vh] bg-white z-[100000] border-4 border-white
 rounded-lg overflow-x-hidden overflow-x-hidden p-4;
}
._subClose {
  @apply w-[30px] cursor-pointer scaleX h-[30px] bg-red-500 text-white
 flex justify-center items-center absolute right-[5px];
}
._chsSubs {
  @apply text-yellow-900 font-medium flex  text-[18px] w-full mt-4 p-4;
}
.chosSubsPln {
  @apply w-full h-auto;
}
.subSCR {
  @apply w-full h-full p-2;
}

@media (max-width: 480px) {
  body {
    @apply overflow-x-hidden overflow-y-auto;
  }
  /*top menu*/
  .navTop {
    @apply flex-col h-auto mb-1 p-0 bg-slate-100;
  }
  .nav-right {
    @apply w-full flex justify-start ml-0 h-auto bg-[#27A8E0] mb-4;
  }
  .nav-right span {
    @apply text-[12px] pr-1 text-white w-[50%]
    flex justify-start items-center;
  }
  .nav-right ._spNtuma {
    @apply hidden;
  }
  .navLeft {
    @apply w-full  relative;
  }
  .navLeft .subscribedU .AccImages {
    @apply w-full bg-red-50
    flex justify-center;
  }
  .pNotLogedIn {
    @apply w-full flex justify-center;
  }
  .spnS {
    @apply w-[100px] mr-2 rounded-lg;
  }
  .alerSubDiv {
    @apply w-[95%];
  }
  /*logo section*/
  .LGadVert {
    @apply pb-3 flex-col h-auto;
  }
  .KinYlGo {
    @apply w-full justify-center h-[70px];
  }
  .kinyLiMg {
    @apply self-center;
  }
  .AdBjYgX {
    @apply w-full h-[120px];
  }
  .p-adverts {
    @apply py-4;
  }
  /*navigation*/
  .MinNav {
    @apply z-[100]  h-[auto] overflow-x-hidden overflow-y-auto mb-4;
  }
  .NavDIV {
    @apply flex-row items-start flex-wrap;
  }
  .NavDIV {
  }
  .MainL {
    @apply h-auto flex justify-center items-center 
    p-2 pr-5 pl-5 relative border-r-[1px]  mb-1;
  }

  .NavDIV .MainL .navGUL {
    @apply z-[1001] min-w-0 flex-col 
    left-[50%] absolute w-[200px]  top-0 min-w-min   bg-white 
    z-50 shadow-lg p-3 visible;
  }

  .AUdXio {
    @apply bg-white absolute sm:w-full w-[95%] h-[80vh] z-[10001]
    left-[2.5%] top-[5vh]  rounded-lg p-2;
  }

  .pClose {
    @apply z-[1000] left-[5px];
  }
  .LgnCont {
    @apply w-[90%] px-2 shadow-none;
  }
  .FrmLGN {
    @apply w-full;
  }

  /*archives*/
  ._archivesss {
    @apply w-full flex flex-col;
  }
  ._archivesss ._archiveL {
    @apply w-full mr-0 bg-slate-200 flex-col flex-nowrap h-auto items-center justify-start;
  }
  .kinyOld_sLgf {
    @apply w-[80%] mr-0;
  }
  ._archiveR {
    @apply w-full flex ml-0 justify-center items-start;
  }
  ._archiveR .archiveLI {
    @apply w-[90%];
  }

  .PTFdf .fCd {
    @apply absolute w-[60px] ml-20 mt-10;
  }
  ._kinyPrevNext .fx11 {
    @apply left-1;
  }
  ._kinyPrevNext .fx22 {
    @apply right-1;
  }

  /*BookViews*/
  ._dvXcX {
    @apply w-[100%] flex flex-wrap justify-center;
  }
  .bookList {
    @apply w-[90%] mr-0;
  }
  .XcnyNs .bookList {
    @apply flex-col w-[85%] h-[500px] text-[13px];
  }
  .bookLeft {
    @apply w-[40%] h-full bg-slate-100;
  }
  .bookLeft img {
    border-radius: 5px 0px 0px 5px;
    @apply w-full h-full;
  }
  .bookRight {
    border-radius: 5px 0px 0px 5px;
    @apply w-[60%] h-full flex flex-col p-4;
  }

  /*News*/
  .news-dv {
    @apply p-0 w-full;
  }
  .ArrLeft {
    @apply w-full h-auto flex flex-col  items-center;
  }
  .Nleft {
    @apply w-[95%] flex-col mt-2 pl-0 min-h-[800px] max-h-max;
  }
  .TpNlXpYCu {
    @apply w-[100%] h-auto rounded-sm flex flex-col;
  }
  .NYnhd {
    @apply w-full flex flex-col  mr-5  relative;
  }
  .topTVCF-Slider {
    @apply min-h-[250px];
  }
  .topCbnh {
  }
  .Innerw {
  }
  .btmYVdg-t {
    @apply max-h-[400px];
  }
  .spNNTtl {
    @apply text-[17px];
  }
  .Nbydksksh {
    @apply w-full mt-8 p-2;
  }
  .NewTitle p {
    @apply text-[16px];
  }
  .NRight {
    @apply w-[100%] flex-col items-center pl-0 pr-0;
  }

  .SlierNF .SliderX._receNtSBV {
    @apply min-w-full w-full
   flex flex-row min-h-[100px];
  }
  ._receNtSBV .NewStop {
    @apply w-[35%] h-full;
  }
  ._receNtSBV .NewsBottom {
    @apply w-[65%] h-full;
  }
  ._receNtSBV .NewsBottom .xnhd {
    @apply text-[100px];
  }
  ._PnewS {
    @apply text-[12px];
  }
  .Svertical .NewStop {
    @apply h-[200px];
  }
  /*Footer */
  .MainFooter {
    @apply flex-col;
  }
  .Fdiv {
    @apply w-full;
  }
  .leftUl {
    @apply w-full flex-col;
  }
  .FcICont {
    @apply w-full flex-col;
  }
  .fcIcX {
    @apply mb-2;
  }

  .kinyOld.F-iconsX {
    @apply top-[35%] p-1 rounded-[50%] cursor-pointer scaleX;
  }
  .fx1 {
    @apply left-0 ml-[5px];
  }
  .fx2 {
    @apply right-0 mr-[5px];
  }

  ._hjHury34 {
    @apply flex-col;
  }
  ._hjHury34 ._titNyt2 {
    @apply w-full mb-5;
  }
  ._hjHury34 ._hhY323 {
    @apply w-full;
  }
}

/*-----------------------Subscriber Accounts-------------------------*/
.SuAxC {
  @apply relative flex flex-row left-0 top-0 h-[100vh] w-screen text-[#383838];
}
.Suxleft {
  @apply fixed left-0 top-0 h-full w-[20%] bg-slate-200;
}
.Lhight {
  @apply border-r-4 border-r-[#E00EF9] relative rounded-sm;
}
.Lhight::after {
  content: "";
  border-color: transparent #e00ef9 transparent transparent;
  @apply absolute top-[10px] right-0 border-[10px];
}
.menuX {
  @apply w-full;
}
.menuX ul li {
  @apply pt-2 pb-2 pl-3 flex justify-center;
}
.SuxvRight {
  @apply relative h-full left-[20%]  w-[80%];
}
.RGhTop {
  @apply h-[50px] w-[80%] bg-white left-[20%] fixed right-0 top-0;
}
.PrcN {
  @apply w-full flex justify-end flex-row mt-2 bg-white;
}
.pNnmx {
  @apply mr-3 h-10 flex justify-center items-center;
}
.ProfileL {
  @apply h-[40px] w-[40px]  bg-slate-300 text-center
    pl-[10px] pt-[10px] pr-2 mr-10 rounded-[50%];
}

.RGhBdy {
  height: calc(100% - 70px);
  @apply w-full mt-12;
}
.DvXD {
  @apply h-full w-full;
}
.SbPlN {
  @apply h-full w-full;
}
.Phdeade {
  @apply h-[50px] text-[30px] text-blue-800  flex justify-center items-center
    p-2 mt-3 w-full text-center text-4xl;
}
.lnSchy {
  @apply text-red-700 font-medium w-full text-center p-3;
}
.bnjBgt {
  @apply text-green-700 p-3 font-medium;
}
.sUBcARD {
  height: calc(100% - 70px);
  @apply mt-3 w-full flex flex-row 
    justify-center items-start flex-wrap;
}
.SubCard {
  box-shadow: 0px 0px 5px #d7dbdd;
  @apply h-[400px] w-[200px] rounded-[15px] 
      mr-5 p-3 mb-2 bg-white;
}
.subPlx {
  @apply w-full pt-5 w-full text-center text-[25px] relative;
}
.subPlx::after {
  content: "";
  transform: translate(-50%);
  @apply absolute h-[2px] w-[20%] bg-[#F2EB11] -bottom-[.5em] left-[50%];
}
.XPrice {
  @apply w-full text-center  p-5 pt-12 text-[25px] font-semibold;
}
.SubXPr {
  @apply text-[16px] font-semibold;
}
.sPbbUY {
  @apply flex justify-center;
}
.sPbbUY button {
  @apply p-3 bg-[#F2EB11] font-semibold text-[black]
      rounded-[15px] mt-5 pl-5 pr-5;
}
.SubnX2 button {
  @apply bg-[#1DA1F2] text-[#EAEDED];
}
.DscXwQ {
  @apply w-auto pt-20;
}
.DscXwQ li {
  @apply flex flex-row w-full justify-start items-center p-[3px];
}
.DscXwQ li .Ls1 {
  @apply text-[#1DA1F2] font-bold mr-2;
}
.Sub1 {
}
.PyMeN {
  @apply w-full flex flex-col justify-center items-center;
}
.PynTtl {
  @apply w-full p-5 text-center text-[2.5em];
}
.Ptytx {
  @apply w-[50%] p-4;
}
.bUYDxd {
  @apply p-4 bg-black ml-3 text-[white] rounded;
}
.Ptytx ul {
  @apply mb-4;
}
.Ptytx ul li {
  @apply p-1 text-[20px];
}
.Ptytx ul li:first-child {
  @apply text-[1.2em] mb-2 font-semibold;
}

/* -----------------------------| subDashBord |---------------------------- */
.Dashboard {
  @apply w-full h-full pt-5 pl-5 pr-7 bg-slate-100 flex text-[14px];
}
.DivLeft {
  @apply w-[60%] h-full mr-10  flex flex-col;
}
.DivRight {
  width: calc(40% - 40px);
  @apply flex flex-col p-3 h-full w-[40%] bg-white;
}
.DivLcd {
  @apply font-semibold;
}
.DivRight label {
  @apply p-3 mb-3 font-semibold;
}
.EdirSec {
  @apply flex flex-col w-full;
}
.DcFlex {
  @apply flex flex-row relative;
}
.DcFlex input {
  @apply w-full;
}
.TcB:after {
  content: "";
  @apply absolute h-[2px] w-[50%] bg-[#F2EB11] -bottom-[.5em] left-[0%];
}
.jjdfZ {
  @apply absolute right-0 top-[10px] scaleX h-full w-5 cursor-pointer;
}
.DivDshCrd {
  @apply h-[200px] w-full mb-10 flex flex-row;
}
.cardON {
  @apply w-[47.5%] p-5 h-full  bg-white;
}
.crd1 {
  @apply mr-[5%];
}

.sUbLft {
  @apply relative font-semibold w-full text-center flex justify-center;
}
.SyBS {
  @apply text-[16px];
}
.DyLft {
  @apply p-8 text-[35px] w-full text-center text-[green];
}
.Dcgd {
  @apply w-full text-center;
}
.DvHistory {
  @apply min-h-[300px] w-full bg-white p-3;
}
.subTl {
  @apply p-3 font-semibold;
}
.tblDS {
  @apply w-full;
}
.tblDS tr {
  @apply bg-white text-center;
}
.tblDS th {
  @apply text-lg font-semibold text-black py-2 px-4 border-l border-transparent
      bg-[#F9E79F];
}
.tblDS td {
  @apply border-b border-[#E8E8E8] py-2;
}
.SttBtN {
  @apply border-[1px] py-1  rounded-lg p-2;
}
.SttBtN.pend {
}
.DivXu {
  @apply flex justify-center;
}
.DivXu button {
  @apply p-2 bg-blue-700 rounded-lg ml-2 text-white;
}

.SttBtN.wtht {
}
.SttBtN.crnt {
  @apply border-[2px] border-[#0A69E2] text-black;
}
.SttBtN.expird {
  @apply border-[#F72F69];
}

/*new Passwprd*/
.RenewP {
  @apply h-full w-full flex justify-center items-start;
}
.FrmXC {
  @apply flex flex-col w-[40%] p-4 mb-4;
}
.FrmXC h1 {
  @apply w-full text-[30px] font-semibold my-6;
}
.FrmXC label {
  @apply py-3;
}
.inputX {
  @apply py-2 px-2 border-[1px] border-[#cccc] rounded-[3px];
}
.submx {
  @apply w-full flex mt-5;
}
.suBxx {
  @apply py-3 px-6 bg-blue-900 text-white rounded-2xl;
}

/*==============Admin====================*/

.AdN {
  @apply w-screen relative h-screen bg-red-50 flex flex-row;
}
.adLeftM {
  transition: all 0.5s;
  @apply w-[250px] z-10 h-full fixed left-0 right-0 bg-[white];
}
.AdAcT .adLeftM {
  @apply w-[50px] shadow-md;
}
.AdAcT .GxcV,
.AdAcT .spTB {
  @apply hidden;
}

.LgN {
  @apply w-full h-[50px] flex justify-start pl-3 pb-5 border-b-[1px]
    border-r-[1px];
}
.spTB {
  @apply h-[40px] mt-1 flex items-center text-[24px] pl-3;
}
.LgIx {
  @apply w-[40px] mt-1 h-[40px] rounded-[50%];
}
.AdAcT .LgIx {
  @apply ml-[-7px];
}

.DnaV {
  @apply w-full h-full;
}
.DnaV ul {
  @apply w-full;
}
.DnaV ul li {
  @apply flex flex-row p-3 relative;
}
.DnaV ul li a {
  @apply flex flex-row;
}
.GIcN {
  transition: all 0.5s;
  @apply font-normal text-[black];
}
.DnaV ul li .GxcV,
.GxcVX,
.GxcVXX {
  @apply -mt-1 ml-1 text-[#2E4053];
}
.DAcX {
  @apply mr-0;
}
.GIcN.G1 {
  @apply text-[#D35400];
}
.GIcN.G3 {
  @apply text-[#873600];
}
.GIcN.G4 {
  @apply text-[#688574];
}
.GIcN.G5 {
  @apply text-[#D4AC0D];
}
.GIcN.G6 {
  @apply text-[#3498DB];
}
.GIcN.G7 {
  @apply text-[#16A085];
}
.GIcN.G8 {
  @apply text-[#CB4335];
}
.GIcN.G9 {
  @apply text-[#EB984E];
}
.GIcN.G10 {
  @apply text-[#8E44AD];
}
.GIcN.G11 {
  @apply text-[#1B4F72];
}

/*RIGHT----------------------*/

.adRightF {
  width: calc(100% - 250px);
  transition: all 0.5s;
  @apply relative 
    bg-slate-50
    left-[250px] h-full flex flex-col overflow-x-hidden overflow-y-auto;
}
.RightT {
  width: calc(100% - 265px);
  @apply fixed h-[50px]  bg-white border-b-[1px] 
    flex flex-row z-[100000];
}
.AdAcT .adRightF {
  width: calc(100% - 50px);
  @apply left-[50px]  shadow-md;
}
.AdAcT .adRightF .RightT {
  width: calc(100% - 65px);
}
.DsIy {
  @apply z-[40] absolute left-[30px] top-[13px] cursor-pointer;
}
.DsIy:active {
  transform: scale(1.04);
}

.LiQuick {
  @apply relative z-30 left-[100px] w-[40%]  flex flex-col 
    justify-center items-center;
}
.UlVd {
  @apply absolute bg-blue-50 top-0 shadow-lg p-5 z-[-1] pt-[50px]  flex flex-row 
    w-full;
}
.UlVd ul {
  @apply w-[50%];
}
.UlVd ul li,
.DnaV ul li {
  background-image: linear-gradient(
    to left,
    transparent,
    transparent 50%,
    #f9e79f 50%,
    #f9e79f
  );
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.25s ease-in;

  @apply p-3;
}
.UlVd ul li:hover,
.DnaV ul li:hover {
  background-position: 0 0;
}
.AShow {
  @apply flex justify-center  flex-row  w-[150px] p-2 bg-blue-700 mt-[2px] rounded-md;
}
.AShow span,
.AShow .G1 {
  @apply text-white;
}
.NbCdc {
  @apply w-full flex flex-row justify-start items-center;
}
.ShWN {
  @apply w-full border-b-2 ml-[0px] bg-transparent rounded-none;
}
.ShWN span,
.ShWN .G1 {
  @apply text-[#383838]  mb-2;
}

.LiQuick {
  @apply cursor-pointer;
}
.HdN {
  @apply hidden;
}
.KnXA {
  @apply border-r-4  border-[#F2EB11] rounded-lg;
}

.DcbYl {
  @apply flex flex-row absolute right-0 mr-4;
}
.PnVui {
  @apply h-[40px] mt-1  mr-3 flex justify-center items-center;
}
.UserF {
  @apply h-[40px] mt-1 w-[40px] bg-slate-200 pl-2 pr-2 pt-[6px] rounded-[50%];
}

.RightB {
  @apply w-full h-[50%]  mt-[50px]  bg-slate-100 z-[1];
}

/*AdminDash*/
.AdDash {
  @apply p-5;
}
.DashSCrd {
}
.DshNmT {
  @apply h-[150px] w-[33.3%] bg-white mr-4;
}
.TimeN {
  @apply h-[20px];
}

/*Dashboard forms*/
.GnFrM {
  transition: all 0.5s;
  @apply h-auto min-h-full w-[100%]  flex flex-row justify-center items-start;
}
.FrmGeneral {
  @apply bg-white rounded-md p-10 min-h-[300px] flex justify-center items-center
    flex-col min-w-[50%] mt-5 text-[15px];
}
.GrM2 {
  @apply min-w-[95%] flex-row p-0 bg-transparent;
}
.AdAcT .adRightF .RightB .GnFrM .FrmGeneral {
  @apply min-w-[45%];
}
.AdAcT .adRightF .RightB .GnFrM .GrM2 {
  @apply min-w-[95%];
}
.FRMtX {
  @apply w-full pb-5 flex justify-start items-center text-[20px] font-semibold;
}
.lblL {
  @apply relative w-full pt-[6px] pb-[6px] mb-2 flex flex-row;
}
.ipNBY {
  @apply absolute right-0 cursor-pointer scaleX h-[35px] w-[35px] pt-[6px] pl-[6px] rounded-[50%];
}
.ipNBY:active {
  @apply bg-slate-300;
}
.INpX,
.seLin,
.TextAr {
  @apply w-full p-2 border-[1px] bg-[#f2f2f2] rounded-md font-medium;
}
.TextAr {
  @apply min-w-full max-w-full min-h-[80px] max-h-[150px];
}
.TextAr.ARN {
  @apply min-h-[150px] max-h-[250px];
}
.AR1 {
  @apply h-[50px] min-h-[50px] max-h-[80px];
}
.SINP {
  @apply w-full flex justify-start mt-5;
}
.SubInp {
  @apply h-full p-3 text-[16px] bg-blue-700 rounded-md text-white;
}
.DcX1 {
  transition: all 0.5s;
  @apply w-full relative;
}
.BNCH {
  @apply flex flex-row my-5;
}
.SPnc,
.xCNYC {
  @apply h-[40px] mr-2 cursor-pointer w-[40px] pl-2 pt-2 rounded-[50%] border-2 scaleX;
}
.CBVG {
  @apply relative flex justify-center w-full  mt-2;
}
.xCNYC {
  @apply absolute right-0 top-0 bg-white border-white shadow-lg;
}
.VxcY {
  @apply shadow-2xl fixed top-[20%] left-[30%] w-[40%] rounded-lg  z-10 h-[300px]
   bg-white;
}
.VxcY::after {
  content: "";
  @apply z-[-1] fixed top-0 left-0 w-screen h-screen bg-slate-800 opacity-[0.15];
}
.HdX {
  @apply hidden;
}


.ads_img_a{
  width: 100% !important;
  height: 90px !important;
}

.ads_img{
  width: 100% !important;
  height: 100% !important;
}

.twitter-embed{
  max-height: 500px !important;
  overflow-y: auto;
}